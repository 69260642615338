<template>
  <div>
    <div id="nav">
      <router-link to="/">home</router-link> |
      <router-link to="/about">əbout</router-link> |
      <router-link to="/visitor">visitor Iog</router-link> |
      <router-link to="/tree">tree generator</router-link><!-- |
      <router-link to="/2048">2048</router-link>-->
    </div>
    <router-view/>
  </div>
</template>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f3efec;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
