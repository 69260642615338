<template>
    <div>
        <h3>
        <span class="mw-headline" id="Noun">Noun</span>
        <span class="mw-editsection">
            <span class="mw-editsection-bracket">[</span>
            <a href="#">edit</a>
            <span class="mw-editsection-bracket">]</span>
        </span>
        </h3>
        <p>
            <strong class="Latn headword" lang="en">drogulus</strong> 
            (<i>plural </i> 
            <b class="Latn form-of lang-en p-form-of" lang="en">
                <a href="#" title="droguli">ıןnƃoɹp</a>
            </b>)
        </p>
        <span class="ib-brac">(</span>
            <span class="ib-content">
                <a href="#" title="philosophy">¿ρɦٱɭѻรѻρɦץ?</a>
            </span>
        <span class="ib-brac">)</span> 
        <span>
An entity whose presence is unverifiable, because it has no physical effects...
        </span>
    </div>
</template>

<script>
export default {
    name: 'Default',
    data(){
        return{
            drogulusDefinite: ''
        }
    }
}
</script>

<style>
.mw-editsection{-moz-user-select:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}
.mw-editsection,.mw-editsection-like{font-size:small;font-weight:normal;margin-left:1em;vertical-align:baseline;line-height:1em}
.mw-content-ltr .mw-editsection,.mw-content-rtl .mw-content-ltr .mw-editsection{margin-left:1em}
.mw-content-rtl .mw-editsection,.mw-content-ltr .mw-content-rtl .mw-editsection{margin-right:1em}
a{text-decoration:none;color:#0645ad;background:none}
a:not([href]){cursor:pointer}a:visited{color:#0b0080}
a:active{color:#faa700}a:hover,a:focus{text-decoration:underline}
a:lang(ar),a:lang(kk-arab),a:lang(mzn),a:lang(ps),a:lang(ur){text-decoration:none}
a.stub{color:#723}a.new,#p-personal a.new{color:#d33}
a.mw-selflink{color:inherit;font-weight:bold;text-decoration:inherit}
a.mw-selflink:hover{cursor:inherit;text-decoration:inherit}
a.mw-selflink:active,a.mw-selflink:visited{color:inherit}
a.new:visited,#p-personal a.new:visited{color:#a55858}
ol{margin:0.3em 0 0 3.2em;padding:0;list-style-image:none}li{margin-bottom:0.1em}
</style>