<template>
    <div class="twenty-forty-eight">
    </div>
</template>

<script>

export default {
    name: 'Twenty',
    components: {
    }

}
</script>

<style>

</style>