<template>
  <div class="home">
    <div id="image-wrap">
      <router-link to="/">
        <img id="my-home-drawing" alt="Vue logo" src="../assets/logo.jpg">
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home'
}

</script>

<style>
#my-home-drawing{
  width: 50%;
}
/*
#image-wrap{
}
*/
</style>
