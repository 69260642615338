<template>
    <div>
        <form @submit.prevent class="visitor-log-field">
            <label for="entry"></label>
            <textarea 
            id="visitor-log-entry" name="log-entry" 
            rows="5" cols="50" v-model="logEntry" @focus="logEntry=''"/>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return{
            logEntry:''||'how\'s it going?.'
        }
    }
}
</script>

<style>

</style>